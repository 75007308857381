@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "GhostKid AOE";
  src: url("./fonts/31052e96613322a8c26d5e59a1930496.eot"); /* IE9*/
  src: url("./fonts/31052e96613322a8c26d5e59a1930496.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/31052e96613322a8c26d5e59a1930496.woff2")
      format("woff2"),
    /* chrome、firefox */ url("./fonts/31052e96613322a8c26d5e59a1930496.woff")
      format("woff"),
    /* chrome、firefox */ url("./fonts/31052e96613322a8c26d5e59a1930496.ttf")
      format("truetype"),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
      url("./fonts/31052e96613322a8c26d5e59a1930496.svg#GhostKid AOE")
      format("svg"); /* iOS 4.1- */
}

@font-face {
  font-family: "Arial Rounded";
  src: url("./fonts/arialroundedmt.eot"); /* IE9*/
  src: url("./fonts/arialroundedmt.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/arialroundedmt.woff2") format("woff2"),
    /* chrome、firefox */ url("./fonts/arialroundedmt.woff") format("woff"),
    /* chrome、firefox */ url("./fonts/arialroundedmt.ttf") format("truetype"),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
      url("./fonts/arialroundedmt.svg#GhostKid AOE") format("svg"); /* iOS 4.1- */
}

body {
  margin: 0;
  font-family: "Arial Rounded";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@layer components {
  .main {
    @apply container mx-auto px-4;
  }

  .H1 {
    @apply font-display text-white text-2xl md:text-4xl text-center md:text-left;
  }

  .H2 {
    @apply text-xl md:text-3xl text-center font-bold uppercase mb-2;
  }

  .H3 {
    @apply text-lg md:text-2xl text-center font-bold uppercase;
  }

  .label,
  .paragraph {
    @apply text-base md:text-2xl;
  }

  .error {
    @apply text-red-600 font-semibold block;
  }
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}